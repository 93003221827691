<template>

<div v-if="selectedScore" id="pdfTemplate" class="container" :style="!historiqueScoreId?'display:none':''" width="400px">
    <div class="p-grid p-justify-between" style=""><div data-v-54132520="">{{selectedScore?selectedScore.clientRaisonSociale:""}}</div> <div data-v-54132520="">{{formatDate(new Date())}}</div></div>
<h5 style="text-align: center;font-weight: bold;margin-bottom: 10px;">Score de la société « {{selectedScore?selectedScore.raisonSociale:""}} »</h5>
<div v-if="!selectedScore.etatPublication && historiqueScoreId" style="    text-align: center;font-size: 16px;color: red;font-weight: bold;">
    Non publié
</div>
<div class="blockTitlePdf">
    Données acheteur 
    <!--<p v-if="selectedScore && selectedScore.outdated" style="color:red">(outdated)</p>-->
</div>
<div  class="divPanelPdf" style="position:relative;" >
    <div :class="'topRightCorner'">
    </div>
    <div class="row">
        <div class="col-4">
        Raison sociale:
        </div>
        <div class="col-8">
        {{selectedScore?selectedScore.raisonSociale:""}}
        </div>
    </div>
        <div class="row">
            <div class="col-4">
            ICE:
            </div>
            <div class="col-8 ">
            {{selectedScore?selectedScore.ice:""}}
            </div>
            <div class="col-4">
            RC:
            </div>
            <div class="col-8">
            {{selectedScore?selectedScore.rc:""}}
            </div>
            <div class="col-4">
            Code interne:
            </div>
            <div class="col-8">
            {{selectedScore?selectedScore.codeInterne:""}}
            </div>
            <div class="col-4">
            Ville:
            </div>
            <div class="col-8">
            {{selectedScore && selectedScore.ville?selectedScore.ville.nomVille:""}}
            </div>
        </div>
    </div>
    <div class="blockTitlePdf">Historique</div>
    <div class="divPanelPdf">
        <div class="row"> 
            <div class="col-4">
                Dernier score:&nbsp; 
            </div>
            <div class="col-8">
                {{selectedScore && selectedScore.dernierScore && selectedScore.dernierScore.score?selectedScore.dernierScore.score.toString()+"/10":"NA"}}
            </div>
            <div class="col-4">
                Analyste:
            </div>
            <div class="col-8">
                {{selectedScore && selectedScore.dernierScore && selectedScore.dernierScore.utilisateur?selectedScore.dernierScore.utilisateur:""}}
            </div>
            <div class="col-4">
                Date:
            </div>
            <div class="col-8">
                {{selectedScore && selectedScore.dernierScore && selectedScore.dernierScore.dateScore?formatDate(selectedScore.dernierScore.dateScore):""}}
            </div>
        </div>    
    </div>
    <div class="blockTitlePdf">Détails du score ({{selectedScore && selectedScore.dateScore?formatDate(selectedScore.dateScore):""}})</div>
    <div class="divPanelPdf">
        <div class="row" style="border-bottom: 1px solid;"> 
            <div class="col-6" style="border-right:1px solid;">
                <div>
                    <div style="text-decoration: underline; font-weight: bold">Score Financier</div>
                </div>
                <div v-if="selectedScore.jsonFinancier">
                    Bilans utilisés:
                    <ul>
                        <li>
                            <span>{{selectedScore.bilanNAnnee}}</span> <span class="bilanPdfDetail">({{bilanNDetail}})</span> 
                        </li>

                        <li v-if="selectedScore.bilanN1Annee">
                            <span>{{selectedScore.bilanN1Annee}}</span> <span class="bilanPdfDetail">({{bilanN1Detail}})</span> 
                        </li>
                    </ul>
                    <div>
                        Poids du score financier:&nbsp;{{selectedScore && selectedScore.poidsScoreFinancier?selectedScore.poidsScoreFinancier.toString()+"%":"NA"}}
                    </div>
                    <div>
                        Score financier:&nbsp;{{selectedScore && selectedScore.scoreFinancier?selectedScore.scoreFinancier.toString()+"%":"NA"}}  
                    </div> 
                </div>
                <div v-else>
                    Non scoré
                </div>
            </div>
            <div class="col-6" style="line-height: 20px;">
                <div>
                        <div style="text-decoration: underline;font-weight: bold">Score Qualitatif</div>
                </div>
                <div v-if="selectedScore.jsonQualitatif">
                    <div>
                        Poids du score qualitatif:&nbsp;{{selectedScore && selectedScore.poidsScoreQualitatif?selectedScore.poidsScoreQualitatif:""}}%
                    </div>
                    <div>
                        Score qualitatif:&nbsp;{{selectedScore && selectedScore.scoreQualitatif?selectedScore.scoreQualitatif:""}}% 
                    </div>
                </div>
                <div v-else>
                    Non scoré
                </div>
            </div>
        </div> 
        <div class="row align-middle"  style="position:relative;"> 
            <div class="col-4">
            SCORE FINAL:
            </div>
            <div class="col-8">
                 <b>{{parsedScore}}/10</b> {{selectedScore && selectedScore.dernierScore && selectedScore.dernierScore.dateScore?"(vs dernier score "+selectedScore.dernierScore.score+"/10 daté du "+formatDate(selectedScore.dernierScore.dateScore)+")":""}}
            </div>
            <div class="col-4">
                Validité: 
            </div>
            <div class="col-8">
                {{selectedScore && selectedScore.dateValidite?formatDate(selectedScore.dateValidite):""}}
            </div>
            <div class="col-4">
                Analyste: 
            </div>
            <div class="col-8">
                {{selectedScore && selectedScore.utilisateur?selectedScore.utilisateur:""}}
            </div>
            <div class="col-4">
                Nature du risque: 
            </div>
            <div class="col-8">
                {{selectedScore && selectedScore.natureRisque?selectedScore.natureRisque:""}} 
            </div>
            <div class="col-4">
                Recommandation: 
            </div>
            <div class="col-8">
                {{selectedScore && selectedScore.recommandation?selectedScore.recommandation:""}}
            </div>
            <br/>
            <div class="col-4" v-if="selectedScore.jsonFinancier || (currentClient.activerChoixLimiteCreditMinimum)">
                Limite de credit: 
            </div>
            <div class="col-8" v-if="selectedScore.jsonFinancier || (currentClient.activerChoixLimiteCreditMinimum)">
                {{selectedScore && selectedScore.limiteCreditRecommandee?formatDecimal0(arrondiDixMilles(selectedScore.limiteCreditRecommandee)):"0"}}
            </div>
        </div>  
    </div>   
</div>
<!-- <div v-else>
    Score Indisponible
</div> -->

</template>

<script>

import CRUDService from "../1-common-components/crud.service";

export default {
    
  name: "loadPdfScore",
  components: {
  },
  props:['historiqueScoreId'],
  emits:   ['pdfLoaded'],
  data() {
    return {
      selectedScore:null,
    };
  },
  mounted()
  {
    if(this.historiqueScoreId)
    {
        this.loadPdfFromSociete()
    }
  },
  computed:{
    currentClient()  {
        return this.$store.state.clientScoringStoreModule.currentClient;
    },
    bilanNDetail()
    {
        
        if(!this.selectedScore.bilanNJsonInfo) {
            return "";
        }
        var bilanNJson = JSON.parse(this.selectedScore.bilanNJsonInfo);
        return this.formatDate(bilanNJson.dateModification) + (bilanNJson.EstInforisk?' - Inforisk':' - Manuel');
    },
    bilanN1Detail()
    {
        if(!this.selectedScore.bilanN1JsonInfo) {
            return "";
        }
        var bilanN1Json = JSON.parse(this.selectedScore.bilanN1JsonInfo);
        return this.formatDate(bilanN1Json.dateModification) + (bilanN1Json.EstInforisk?' - Inforisk':' - Manuel');
    },

      parsedScore()
      {
        var scoreReturn = this.selectedScore?Math.floor(this.selectedScore.score):0;
        return isNaN(scoreReturn)?0:scoreReturn;
      }
  },
  methods:{
    loadPdfFromSociete: function(historiqueScoreId) {

            if(this.historiqueScoreId)
            {
                historiqueScoreId = this.historiqueScoreId
            }
            
            CRUDService.get(historiqueScoreId, "gethistoriquescorebyid").then(
                (response) => {
                    this.log(response.data);
                    if(response.data)
                    {   
                        
                        this.selectedScore = response.data;
                        //setTimeout(function () {
                        if(!this.historiqueScoreId)
                        {
                                                 
                         this.$nextTick(function(){
                             var dateScoreMoment = this.getMomentFromDate(response.data.dateScore);
                              
                             var dateParsed = dateScoreMoment.format("DDMMyyyy");
                             var nomFichier = "Score_"+response.data.raisonSociale.replace(/\W/g, '')+"_"+dateParsed+".pdf";
                             this.drawDom(nomFichier);
                         })//, 1000)
                        }
                    }
                    else
                    {
                        this.$toast.add({severity:'error', summary: 'Score manquant', detail:"Cette société n'a toujours pas de score", life: 3000});
                    }
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            )
        },
    drawDom: function(filename)
    {
      $("#pdfTemplate").css("display","block")
      kendo.drawing.drawDOM($("#pdfTemplate")).then(function (group) {
        return kendo.drawing.exportPDF(group, {

            margin: { left: "1cm", top: "1cm", right: "1cm", bottom: "1cm" },
            paperSize:"A4",
            scale:0.5,
        });
      })
      .done(function (data) {
            // Save the PDF file
            kendo.saveAs({
                dataURI: data,
                fileName: filename,
            });
            $("#pdfTemplate").css("display","none")
      })

    },
    
  }
};
</script>

<style>

          #pdfTemplate {
              width: 535px;
              font-size: 12px;
            }

          .divPanelPdf
          {
            border: 1px solid rgb(0 0 0);
            border-radius: 4px;
            /* padding-left: 22px; */
            margin: 10px 0px;
            /* line-height: 5px; */
          }

          .divPanelPdf .row
          {
            margin-right:0px !important;
            margin-left:0px !important;
          }

          .divPanelPdf [class*='col-']{ /* contains col-lg in class name */
            padding:0 !important
            }

          .blockTitlePdf
          {
              font-size:16px;
              font-weight: bold;
          }

          .bilanPdfDetail {
            font-size:10px;
            color:gray;
          }

          #pdfTemplate ul
          {
            margin-bottom: 0rem;
          }
</style>